<template>
  <v-container class="content ma-0 pa-4">
    <v-card mb-3 class="py-4">
      <h4 class="card-title text-center">Avaliação dos consultores</h4>
      <v-form
        id="form"
        ref="form"
        v-model="valid"
        enctype="multipart/form-data"
        class="pa-3"
        lazy-validation
      >
        <v-card class="px-4 mx-4 my-4 py-4">
          <h3 class="ml-2">Desempenho geral</h3>
          <v-row>
            <v-col class="d-flex" cols="6">
              <v-text-field
                v-model.number="form.geral"
                label="Avaliação geral"
                type="number"
                :disabled="true"
                :min="1"
                :max="5"
                :rules="[validationRatingValue]"
              ></v-text-field>
              <v-rating
                v-model.number="form.geral"
                readonly
                background-color="orange lighten-3"
                color="orange"
                large
                half-increments
                hover
              ></v-rating>
            </v-col>

            <v-col class="d-flex" cols="6">
              <v-text-field
                v-model.number="form.efetividade"
                label="Efetividade / Relevância no campo"
                type="number"
                :min="1"
                :max="5"
                :rules="[validationRatingValue]"
              ></v-text-field>
              <v-rating
                v-model.number="form.efetividade"
                background-color="orange lighten-3"
                color="orange"
                large
                half-increments
                hover
              ></v-rating>
            </v-col>

            <v-col class="d-flex" cols="6">
              <v-text-field
                v-model.number="form.engajamento"
                label="Engajamento nas redes sociais"
                type="number"
                :min="1"
                :max="5"
                :rules="[validationRatingValue]"
              ></v-text-field>
              <v-rating
                v-model.number="form.engajamento"
                background-color="orange lighten-3"
                color="orange"
                large
                half-increments
                hover
              ></v-rating>
            </v-col>

            <v-col class="d-flex" cols="6">
              <v-text-field
                v-model.number="form.influencia"
                label="Influência no grupo"
                type="number"
                :min="1"
                :max="5"
                :rules="[validationRatingValue]"
              ></v-text-field>
              <v-rating
                v-model.number="form.influencia"
                background-color="orange lighten-3"
                color="orange"
                large
                half-increments
                hover
              ></v-rating>
            </v-col>

            <v-col class="d-flex" cols="6">
              <v-text-field
                v-model.number="form.pontualidade"
                label="Pontualidade nas entregas"
                type="number"
                :min="1"
                :max="5"
                :rules="[validationRatingValue]"
              ></v-text-field>
              <v-rating
                v-model.number="form.pontualidade"
                background-color="orange lighten-3"
                color="orange"
                large
                half-increments
                hover
              ></v-rating>
            </v-col>

            <v-col class="d-flex" cols="6">
              <v-text-field
                v-model.number="form.execucao"
                label="Execução agenda de visitas"
                type="number"
                :min="1"
                :max="5"
                :rules="[validationRatingValue]"
              ></v-text-field>
              <v-rating
                v-model.number="form.execucao"
                background-color="orange lighten-3"
                color="orange"
                large
                half-increments
                hover
              ></v-rating>
            </v-col> </v-row
        ></v-card>

        <v-card class="px-4 mx-4 my-4 py-4">
          <h3 class="ml-2">Evolução técnica</h3>
          <v-row>
            <v-col class="d-flex" cols="6">
              <v-text-field
                v-model.number="form.evolucao_tecnica"
                label="Evolução técnica"
                type="number"
                :disabled="true"
                :min="1"
                :max="5"
                :rules="[validationRatingValue]"
              ></v-text-field>
              <v-rating
                v-model.number="form.evolucao_tecnica"
                readonly
                background-color="orange lighten-3"
                color="orange"
                large
                half-increments
                hover
                :disabled="true"
              ></v-rating>
            </v-col>

            <v-col class="d-flex" cols="6">
              <v-text-field
                v-model.number="form.planejamento_producao"
                label="Planejamento e produção volumoso"
                type="number"
                :min="1"
                :max="5"
                :rules="[validationRatingValue]"
              ></v-text-field>
              <v-rating
                v-model.number="form.planejamento_producao"
                background-color="orange lighten-3"
                color="orange"
                large
                half-increments
                hover
              ></v-rating>
            </v-col>

            <v-col class="d-flex" cols="6">
              <v-text-field
                v-model.number="form.nutricao"
                label="Nutrição"
                type="number"
                :min="1"
                :max="5"
                :rules="[validationRatingValue]"
              ></v-text-field>
              <v-rating
                v-model.number="form.nutricao"
                background-color="orange lighten-3"
                color="orange"
                large
                half-increments
                hover
              ></v-rating>
            </v-col>

            <v-col class="d-flex" cols="6">
              <v-text-field
                v-model.number="form.qualidade_do_leite"
                label="Qualidade do leite"
                type="number"
                :min="1"
                :max="5"
                :rules="[validationRatingValue]"
              ></v-text-field>
              <v-rating
                v-model.number="form.qualidade_do_leite"
                background-color="orange lighten-3"
                color="orange"
                large
                half-increments
                hover
              ></v-rating>
            </v-col>

            <v-col class="d-flex" cols="6">
              <v-text-field
                v-model.number="form.ireproducao"
                label="Ireprodução"
                type="number"
                :min="1"
                :max="5"
                :rules="[validationRatingValue]"
              ></v-text-field>
              <v-rating
                v-model.number="form.ireproducao"
                background-color="orange lighten-3"
                color="orange"
                large
                half-increments
                hover
              ></v-rating>
            </v-col>

            <v-col class="d-flex" cols="6">
              <v-text-field
                v-model.number="form.gestao_financeira"
                label="Gestão econômica e financeira"
                type="number"
                :min="1"
                :max="5"
                :rules="[validationRatingValue]"
              ></v-text-field>
              <v-rating
                v-model.number="form.gestao_financeira"
                background-color="orange lighten-3"
                color="orange"
                large
                half-increments
                hover
              ></v-rating>
            </v-col> </v-row
        ></v-card>

        <div class="text-end pr-4">
          <v-btn
            :disabled="loading.save"
            :loading="loading.save"
            :color="!$route.query.id ? 'success' : 'warning'"
            @click="createOrUpdate"
          >
            Nova Avaliação
          </v-btn>
          <router-link
            :disabled="loading.save"
            :to="`/${$user.get().role}/consultores`"
          >
            <v-btn :disabled="loading.save" color="error" class="ml-3">
              Cancelar
            </v-btn>
          </router-link>
        </div>
      </v-form>
    </v-card>

    <base-loading v-if="loading.data" />
    <v-card v-if="data.length">
      <v-card-title class="text-center">Avaliações</v-card-title>
      <v-card-text>
        <AvaliationList :data="data"></AvaliationList>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import AvaliationService from '../../services/api/avaliation'
import AvaliationList from './AvaliationList.vue'

export default {
  name: 'AvaliationForm',
  components: { AvaliationList },

  props: {
    consultor: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      valid: true,
      loading: {
        save: false,
        data: false,
      },

      form: {
        userId: this.consultor,

        geral: 0,

        efetividade: 0,
        engajamento: 0,
        influencia: 0,
        pontualidade: 0,
        execucao: 0,

        evolucao_tecnica: 0,
        gestao_financeira: 0,
        ireproducao: 0,
        qualidade_do_leite: 0,
        nutricao: 0,
        planejamento_producao: 0,
      },

      data: [],

      validationRatingValue: value => {
        return (value >= 1 && value <= 5) || 'Valor deve ser entre 1 e 5'
      },
    }
  },

  computed: {},

  watch: {
    form: {
      handler() {
        this.avgEvolucaoTecnica()
        this.avgGeral()
      },
      deep: true,
    },
  },

  created() {
    this.loadList()
  },

  methods: {
    avgEvolucaoTecnica() {
      const {
        planejamento_producao,
        nutricao,
        qualidade_do_leite,
        ireproducao,
        gestao_financeira,
      } = this.form

      if (
        !planejamento_producao ||
        !nutricao ||
        !qualidade_do_leite ||
        !ireproducao ||
        !gestao_financeira
      )
        return (this.form.evolucao_tecnica = 0)

      const avg =
        (planejamento_producao +
          nutricao +
          qualidade_do_leite +
          ireproducao +
          gestao_financeira) /
        5

      this.form.evolucao_tecnica = avg
    },

    avgGeral() {
      const {
        efetividade,
        engajamento,
        influencia,
        pontualidade,
        execucao,
      } = this.form

      if (
        !efetividade ||
        !engajamento ||
        !influencia ||
        !pontualidade ||
        !execucao
      )
        return (this.form.geral = 0)

      const avg =
        (efetividade + engajamento + influencia + pontualidade + execucao) / 5

      this.form.geral = avg
    },

    async createOrUpdate() {
      this.valid = this.$refs.form.validate()

      if (!this.valid) {
        return this.Toast().fire({
          icon: 'error',
          title: 'Preencha todos os campos corretamente.',
        })
      }

      try {
        this.loading.save = true

        await this.create()
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao criar avaliação, tente novamente.',
        })
      } finally {
        this.loading.save = false
      }
    },

    async create() {
      try {
        const response = await AvaliationService.create(this.form)

        if (response.status !== 201) throw new Error()

        this.Toast().fire({
          icon: 'success',
          title: 'Avaliação criada com sucesso.',
        })

        this.$router.go(-1)
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao criar avaliação, tente novamente.',
        })
      }
    },

    async loadList() {
      this.loading.data = true
      try {
        const response = await AvaliationService.findByUser(this.consultor)

        if (response.status !== 200) throw new Error()

        this.data = response.data.data
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao carregar avaliações, tente novamente.',
        })
      } finally {
        this.loading.data = false
      }
    },
  },
}
</script>
