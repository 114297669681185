import axios from '../../../api/axios'

export default {
  async find(id) {
    return await axios.get(`/avaliation/${id}`)
  },

  async findByUser(userId) {
    return await axios.get(`/avaliation/user/${userId}`)
  },

  async create(data) {
    return await axios.post('/avaliation', data)
  },

  async update(id, data) {
    return await axios.put(`/avaliation/${id}`, data)
  },

  async delete(id) {
    return await axios.delete(`/avaliation/${id}`)
  },
}
