<template>
  <v-card class="ma-3">
    <base-loading v-if="loading.loading" />
    <crud-list
      v-else
      v-model="list"
      :headers="headers"
      :slots="['item.geral', 'item.evolucao_tecnica', 'item.created_at']"
      :actions="actions"
    >
      <template v-slot:item.geral="{ item }">
        <v-rating
          v-model="item.geral"
          :half-increments="true"
          :dense="true"
          :readonly="true"
          background-color="orange lighten-3"
          color="orange"
        ></v-rating>
      </template>
      <template v-slot:item.evolucao_tecnica="{ item }">
        <v-rating
          v-model="item.evolucao_tecnica"
          :half-increments="true"
          :dense="true"
          :readonly="true"
          background-color="orange lighten-3"
          color="orange"
        ></v-rating>
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ moment(item.created_at).format('DD/MM/YYYY HH:mm') }}
      </template>
    </crud-list>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Avaliação do consultor</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-card>
                <v-row class="py-2 px-4">
                  <v-col cols="12" sm="6">
                    <h5>Demsempenho geral</h5>

                    <div class="d-flex text-center">
                      <v-rating
                        v-model="selected.geral"
                        :half-increments="true"
                        :dense="true"
                        :readonly="true"
                        background-color="orange lighten-3"
                        color="orange"
                      ></v-rating>
                      <sub>{{ selected.geral }}</sub>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <h5>Efetividade / Relevância no campo</h5>

                    <div class="d-flex text-center">
                      <v-rating
                        v-model="selected.efetividade"
                        :half-increments="true"
                        :dense="true"
                        :readonly="true"
                        background-color="orange lighten-3"
                        color="orange"
                      ></v-rating>
                      <sub>{{ selected.efetividade }}</sub>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <h5>Engajamento nas redes sociais</h5>

                    <div class="d-flex text-center">
                      <v-rating
                        v-model="selected.engajamento"
                        :half-increments="true"
                        :dense="true"
                        :readonly="true"
                        background-color="orange lighten-3"
                        color="orange"
                      ></v-rating>
                      <sub>{{ selected.engajamento }}</sub>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <h5>Influência no grupo</h5>

                    <div class="d-flex text-center">
                      <v-rating
                        v-model="selected.influencia"
                        :half-increments="true"
                        :dense="true"
                        :readonly="true"
                        background-color="orange lighten-3"
                        color="orange"
                      ></v-rating>
                      <sub>{{ selected.influencia }}</sub>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <h5>Pontualidade nas entregas</h5>

                    <div class="d-flex text-center">
                      <v-rating
                        v-model="selected.pontualidade"
                        :half-increments="true"
                        :dense="true"
                        :readonly="true"
                        background-color="orange lighten-3"
                        color="orange"
                      ></v-rating>
                      <sub>{{ selected.pontualidade }}</sub>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <h5>Execução agenda de visitas</h5>

                    <div class="d-flex text-center">
                      <v-rating
                        v-model="selected.execucao"
                        :half-increments="true"
                        :dense="true"
                        :readonly="true"
                        background-color="orange lighten-3"
                        color="orange"
                      ></v-rating>
                      <sub>{{ selected.execucao }}</sub>
                    </div>
                  </v-col>
                </v-row>
              </v-card>

              <v-card class="mt-4">
                <v-row class="py-2 px-4">
                  <v-col cols="12" sm="6">
                    <h5>Evolução técnica</h5>

                    <div class="d-flex text-center">
                      <v-rating
                        v-model="selected.evolucao_tecnica"
                        :half-increments="true"
                        :dense="true"
                        :readonly="true"
                        background-color="orange lighten-3"
                        color="orange"
                      ></v-rating>
                      <sub>{{ selected.evolucao_tecnica }}</sub>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <h5>Planejamento e produção volumoso</h5>

                    <div class="d-flex text-center">
                      <v-rating
                        v-model="selected.planejamento_producao"
                        :half-increments="true"
                        :dense="true"
                        :readonly="true"
                        background-color="orange lighten-3"
                        color="orange"
                      ></v-rating>
                      <sub>{{ selected.planejamento_producao }}</sub>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <h5>Nutrição</h5>

                    <div class="d-flex text-center">
                      <v-rating
                        v-model="selected.nutricao"
                        :half-increments="true"
                        :dense="true"
                        :readonly="true"
                        background-color="orange lighten-3"
                        color="orange"
                      ></v-rating>
                      <sub>{{ selected.nutricao }}</sub>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <h5>Qualidade do leite</h5>

                    <div class="d-flex text-center">
                      <v-rating
                        v-model="selected.qualidade_do_leite"
                        :half-increments="true"
                        :dense="true"
                        :readonly="true"
                        background-color="orange lighten-3"
                        color="orange"
                      ></v-rating>
                      <sub>{{ selected.qualidade_do_leite }}</sub>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <h5>Ireprodução</h5>

                    <div class="d-flex text-center">
                      <v-rating
                        v-model="selected.ireproducao"
                        :half-increments="true"
                        :dense="true"
                        :readonly="true"
                        background-color="orange lighten-3"
                        color="orange"
                      ></v-rating>
                      <sub>{{ selected.ireproducao }}</sub>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <h5>Gestão econômica e financeira</h5>

                    <div class="d-flex text-center">
                      <v-rating
                        v-model="selected.gestao_financeira"
                        :half-increments="true"
                        :dense="true"
                        :readonly="true"
                        background-color="orange lighten-3"
                        color="orange"
                      ></v-rating>
                      <sub>{{ selected.gestao_financeira }}</sub>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-container>
            <small class="d-block text-right mr-2"
              >Realizado em
              {{ moment(selected.created_at).format('llll') }}</small
            >
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import moment from 'moment'

export default {
  components: {},

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    const btnAction = []

    const viewAvaliation = {
      title: 'Visualizar avaliação',
      color: 'primary',
      click: item => this.handleOpenDialog(item),
      icon: 'mdi-eye',
    }

    btnAction.push(viewAvaliation)

    return {
      moment,
      list: [],
      actions: btnAction,
      headers: [
        {
          text: '',
          sortable: false,
          width: '40px',
          value: 'actions',
          align: 'left',
        },
        {
          text: 'Consultor',
          value: 'user.name',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Desempenho geral',
          value: 'geral',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Evolução técnica',
          value: 'evolucao_tecnica',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Realizado em',
          value: 'created_at',
          align: 'left',
          sortable: false,
        },
      ],

      loading: {
        loading: false,
      },

      selected: {},
      dialog: false,
    }
  },

  computed: {},

  mounted() {},

  created() {
    this.list = this.orderList(this.data)
  },

  methods: {
    handleOpenDialog(item) {
      this.selected = item
      this.dialog = true
    },

    orderList(data) {
      return data.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at)
      })
    },
  },
}
</script>
