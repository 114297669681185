<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else class="content">
      <template>
        <v-tabs
          v-model="tabs"
          centered
          background-color="transparent"
          icons-and-text
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab @click="tabs = 0">
            {{ cadastro ? 'Edição' : 'Cadastro' }}
            <v-icon>mdi-account</v-icon>
          </v-tab>

          <v-tab v-if="cadastro" @click="tabs = 1">
            Avaliações
            <v-icon> mdi-account-star</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <v-card
              :class="{ 'card-success': cadastro, 'card-warn': !cadastro }"
              style="min-height: 60vh"
            >
              <h4 v-if="!cadastro" slot="header" class="card-title text-center">
                Edição de Consultor
              </h4>
              <h4 v-else slot="header" class="card-title text-center">
                Cadastro de Consultores
              </h4>
              <div class="clearfix" />
              <div>
                <v-form
                  id="form"
                  ref="form"
                  v-model="valid"
                  enctype="multipart/form-data"
                  class="pa-3"
                  lazy-validation
                  @submit.prevent="action"
                >
                  <v-card>
                    <v-list-item-content>
                      <v-card-title class="headline mx-auto">
                        Dados do Consultor
                      </v-card-title>
                      <v-row v-if="!loading" class="pa-3">
                        <v-col cols="6">
                          <v-text-field
                            v-model="form.name"
                            autocomplete="autocomplete_off_hack_xfr4!k"
                            :rules="requiredRules"
                            item-text="text"
                            label="Nome *"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="form.email"
                            name="email"
                            label="Email *"
                            :rules="emailRules"
                            :disabled="!cadastro ? false : user"
                            required
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-autocomplete
                            v-model="form.sexo"
                            autocomplete="autocomplete_off_hack_xfr4!k"
                            :rules="requiredRules"
                            :items="sexos"
                            item-text="text"
                            item-value="value"
                            label="Sexo *"
                            required
                          />
                        </v-col>
                        <v-col cols="2">
                          <v-menu
                            v-model="selectDate"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :value="
                                  moment(form.birthday).format('DD/MM/YYYY')
                                "
                                label="Data de Nascimento *"
                                prepend-icon="mdi-calendar"
                                color="teal"
                                readonly
                                :rules="requiredRules"
                                required
                                v-bind="attrs"
                                v-on="on"
                              />
                            </template>
                            <v-date-picker
                              v-model="form.birthday"
                              style="margin: 0px"
                              color="blue"
                              no-title
                              :max="maxDate.toISOString().substr(0, 10)"
                              min="1900-01-01"
                              locale="pt-br"
                              @input="selectDate = false"
                            />
                          </v-menu>
                        </v-col>
                        <v-col cols="5">
                          <v-text-field
                            v-model="form.cpf"
                            v-mask="maskCpf"
                            name="cpf"
                            label="CPF *"
                            :rules="cpfRules"
                            required
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="form.formacao"
                            autocomplete="autocomplete_off_hack_xfr4!k"
                            :rules="requiredRules"
                            item-text="text"
                            label="Formação  *"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="form.matricula"
                            autocomplete="autocomplete_off_hack_xfr4!k"
                            :rules="requiredRules"
                            item-text="text"
                            label="Matrícula  *"
                            required
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="form.instituicao"
                            autocomplete="autocomplete_off_hack_xfr4!k"
                            :rules="requiredRules"
                            item-text="text"
                            label="Instituição de Curso Superior  *"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="form.telefone"
                            v-mask="maskTelCel"
                            :rules="[...requiredRules, ...telRules]"
                            label="Telefone Celular *"
                            type="tel"
                            required
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="form.telefone_residencial"
                            v-mask="maskTelRes"
                            :rules="[...telRules]"
                            label="Tel. Residencial"
                            type="tel"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="form.telefone_comercial"
                            v-mask="maskTelRes"
                            :rules="[...telRules]"
                            label="Telefone Comercial"
                            type="tel"
                          />
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-card>
                  <v-card>
                    <v-list-item-content>
                      <v-card-title class="headline mx-auto">
                        Endereço
                      </v-card-title>
                      <v-row v-if="!loading" class="pa-3">
                        <v-col cols="6">
                          <v-autocomplete
                            v-model="form.estado"
                            autocomplete="autocomplete_off_hack_xfr4!k"
                            label="Estado *"
                            :items="estado"
                            :rules="requiredRules"
                            item-text="Nome"
                            item-value="CodEstado"
                            :loading="!loadingData.estado"
                            :disabled="!loadingData.estado"
                            required
                            @change="changeCidade"
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-autocomplete
                            v-model="form.cidade"
                            autocomplete="autocomplete_off_hack_xfr4!k"
                            label="Cidade *"
                            :items="cidade"
                            :rules="requiredRules"
                            item-text="Nome"
                            item-value="CodCidade"
                            :loading="!loadingData.cidade"
                            :disabled="!loadingData.cidade"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="form.logradouro"
                            label="Logradouro *"
                            :rules="requiredRules"
                            required
                          />
                        </v-col>
                        <v-col cols="2">
                          <v-text-field
                            v-model="form.numero"
                            label="Numero *"
                            :rules="requiredRules"
                            required
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="form.bairro"
                            label="Bairro *"
                            :rules="requiredRules"
                            required
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="form.complemento"
                            label="Complemento *"
                            :rules="requiredRules"
                            required
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="form.cep"
                            v-mask="maskCep"
                            type="text"
                            :rules="cepRules"
                            label="CEP *"
                            required
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            v-model="form.latitude"
                            label="Latitude"
                            required
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            v-model="form.longitude"
                            label="Longitude"
                            required
                          />
                        </v-col>
                      </v-row>
                      <v-card class="pa-0 px-5 pb-5">
                        <v-card-title class="headline mx-auto ma-2 px-0">
                          Foto do usuário
                        </v-card-title>
                        <crud-image-uploader
                          ref="form"
                          v-model="form.avatar"
                          dialog-title="Selecione uma imagem"
                          :editable="editLogo"
                          no-data="img/no-avatar.jpg"
                          @delete="deleteCall"
                          @upload="editLogo = true"
                        />
                      </v-card>
                    </v-list-item-content>
                  </v-card>
                  <v-card v-if="$user.get().role === 'admin'">
                    <v-list-item-content>
                      <v-card-title class="headline mx-auto">
                        Permissões
                      </v-card-title>
                      <v-row class="pa-3">
                        <v-col cols="6" sm="4">
                          <v-autocomplete
                            v-model="form.permissions"
                            autocomplete="autocomplete_off_hack_xfr4!k"
                            label="Relatórios "
                            name="permissions"
                            :items="permissions"
                            :loading="loadingData.loadingPermissions"
                            :disabled="loadingData.permissions"
                            item-text="title"
                            item-value="report_id"
                            clearable
                            multiple
                          />
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-card>
                  <v-card>
                    <v-list-item-content>
                      <v-card-title class="headline mx-auto">
                        Configuração
                      </v-card-title>
                      <v-col cols="8" sm="2">
                        <vuetify-money
                          v-model="form.valorkm"
                          name="fuelPrice"
                          label="Valor do Custo/KM"
                          :options="options"
                        />
                      </v-col>
                    </v-list-item-content>
                  </v-card>
                  <v-btn
                    type="submit"
                    :disabled="salvando"
                    :loading="salvando"
                    :color="!$route.query.id ? 'success' : 'warning'"
                    form="form"
                  >
                    {{ btnNome }}
                  </v-btn>
                  <router-link
                    :disabled="salvando"
                    :to="`/${$user.get().role}/consultores`"
                  >
                    <v-btn :disabled="salvando" color="error" class="ml-3">
                      Cancelar
                    </v-btn>
                  </router-link>
                </v-form>
              </div>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <AvaliationForm :consultor="cadastro" />
          </v-tab-item>
        </v-tabs-items>
      </template>
    </div>
  </v-card>
</template>
<script>
import { ENV_APP } from '../../../env'
import { validaCPF } from '../../utils/validacoes'
import AvaliationForm from './AvaliationForm.vue'

export default {
  components: {
    AvaliationForm,
  },
  data() {
    return {
      tabs: 0,

      options: {
        locale: 'pt-BR',
        prefix: 'R$',
        suffix: '',
        length: 4,
        precision: 2,
      },
      activePicker: null,
      maxDate: new Date(),
      selectDate: false,
      edit: false,
      salvando: false,
      loadingData: {
        estado: false,
        cidade: false,
        permissions: false,
        loadingPermissions: true,
      },
      loading: false,
      maskKM: '##,##',
      maskCpf: '###.###.###-##',
      maskTelRes: '(##) ####-####',
      maskTelCel: '(##) # ####-####',
      maskCep: '##.###-###',
      cepRules: [
        v => !!v || 'CEP é obrigatório',
        v => v?.length >= 10 || 'CEP inválido',
      ],
      KMRules: [
        v => !!v || '* Obrigatório',
        v =>
          v?.length >= 5 ||
          'Formato inválido, utilize o formato real brasileiro! ex: 12,50',
      ],
      requiredRules: [v => !!v || '* Obrigatório'],
      emailRules: [v => /.+@.+/.test(v) || 'E-mail invalido'],
      telRules: [
        v => !v || v.length === 0 || v.length >= 10 || '* Número inválido',
      ],
      cpfRules: [
        v => !!v || '* Obrigatório',
        v => validaCPF(v) || '* CPF inválido',
      ],
      valid: null,
      btnNome: !this.$route.query.id ? 'Salvar' : 'Salvar Alterações',
      sexos: [
        {
          text: 'Feminino',
          value: 'Feminino',
        },
        {
          text: 'Masculino',
          value: 'Masculino',
        },
      ],
      form: {
        name: '',
        email: '',
        cpf: '',
        birthday: '2000-01-01',
        sexo: '',
        matricula: '',
        instituicao: '',
        formacao: '',
        telefone_residencial: '',
        telefone: '',
        telefone_comercial: '',
        codEndereco: '',
        estado: 0,
        cidade: 0,
        bairro: '',
        logradouro: '',
        numero: '',
        complemento: '',
        cep: '',
        permissions: [],
        valorkm: '',
        avatar: '',
        latitude: '',
        longitude: '',
      },

      user: '',

      estado: [{ Nome: 'Carregando', CodEstado: 0 }],
      cidade: [{ Nome: 'Selecione um Estado', CodCidade: 0 }],
      permissions: [{ title: 'Carregando', report_id: 0 }],
      cadastro: this.$route.query.id,

      editLogo: false,
      dialogRemoveLogo: false,
      buttonRemoveLogo: false,
      removedLogo: false,
      tmpLogo: '',
    }
  },

  created() {
    if (!this.$route.query.id) {
      this.loadNewForm()
    } else {
      this.form.cadastro = this.$route.query.id
      this.loadOldForm()
    }

    const d = new Date()
    this.maxDate = new Date(
      `${d.getFullYear() - 18}-${d.getMonth() + 1}-${d.getDate()}`,
    )
  },

  methods: {
    getForm() {
      const data = {
        name: this.form.name,
        email: this.form.email,
        cpf: this.form.cpf,
        sexo: this.form.sexo,
        birthday: this.form.birthday,
        matricula: this.form.matricula,
        instituicao: this.form.instituicao,
        formacao: this.form.formacao,
        valorkm:
          parseFloat(this.form.valorkm?.toString().replace(',', '.')) ?? null,
        telefone: this.form.telefone,
        telefone_residencial: this.form.telefone_residencial,
        telefone_comercial: this.form.telefone_comercial,
        codEndereco: this.form.codEndereco,
        estado: this.form.estado,
        cidade: this.form.cidade,
        bairro: this.form.bairro,
        logradouro: this.form.logradouro,
        numero: this.form.numero,
        complemento: this.form.complemento,
        cep: this.form.cep,
        permissions: this.form.permissions.map(permission => {
          return { report_id: permission }
        }),
        avatar: this.form.avatar,
        latitude: this.form.latitude,
        longitude: this.form.longitude,
      }

      return data
    },

    action() {
      this.salvando = !this.salvando

      this.valid = this.$refs.form.validate()

      if (!this.valid) {
        this.salvando = !this.salvando
        this.Swal.fire(
          'Atenção',
          'Preencha os itens obrigatórios sinalizados com ("*").',
          'warning',
        )
        return
      }

      if (!this.cadastro) {
        this.create()
      } else {
        this.update()
      }
    },

    addZeroes(num, len) {
      let numberWithZeroes = String(num)
      let counter = numberWithZeroes.length

      while (counter < len) {
        numberWithZeroes = '0' + numberWithZeroes

        counter++
      }

      return numberWithZeroes
    },

    update() {
      const data = this.getForm()

      try {
        this.api.update.consultor(this.cadastro, data).then(() => {
          this.Swal.fire({
            title: 'Sucesso',
            text: 'Consultor atualizado com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
          })
            .then(() => {
              this.$router.push({
                path: `/${this.$user.get().role}/consultores`,
              })
              this.salvando = !this.salvando
            })
            .catch(() => {
              this.salvando = !this.salvando
            })
        })
      } catch (error) {
        this.salvando = !this.salvando
        this.Swal.fire({
          title: 'Atenção',
          text: 'Ocorreu um erro ao atualizar o produtor, tente novamente!.',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    },

    async loadOldForm() {
      this.loading = true

      try {
        this.api.get.consultor(this.cadastro).then(res => {
          const data = res[0]
          this.user = !!data?.user_id
          this.form.name = data?.Pessoa?.pessoafisica?.Nome
          this.form.email = data?.Pessoa?.email
          this.form.cpf = data?.Pessoa?.pessoafisica?.Cpf
          this.form.birthday =
            data?.Pessoa?.pessoafisica?.DataNascimento ?? '2000-01-01'
          this.form.sexo = data?.Pessoa?.pessoafisica?.sexo
          this.form.matricula = data?.Matricula
          this.form.valorkm = data?.ValorKM ? data?.ValorKM : null
          this.form.instituicao = data?.Instituicao
          this.form.formacao = data?.Formacao
          this.form.telefone_residencial = data?.Pessoa?.TelRes
          this.form.telefone = data?.Pessoa?.TelCel
          this.form.telefone_comercial = data?.Pessoa?.TelRec

          this.form.codEndereco = data?.Pessoa?.endereco?.CodEndereco
          this.form.cidade = data?.Pessoa?.endereco?.cidade?.CodCidade
          this.form.estado = data?.Pessoa?.endereco?.cidade?.CodEstado
          this.form.bairro = data?.Pessoa?.endereco?.Bairro
          this.form.logradouro = data?.Pessoa?.endereco?.Logradouro
          this.form.numero = data?.Pessoa?.endereco?.Numero
          this.form.complemento = data?.Pessoa?.endereco?.Complemento
          this.form.cep = data?.Pessoa?.endereco?.Cep
          this.form.latitude = data?.Pessoa?.endereco?.Latitude
          this.form.longitude = data?.Pessoa?.endereco?.Longitude
          this.form.permissions = data?.permissions?.map(item =>
            parseInt(item.report_id),
          )
          this.form.avatar = data?.avatar
          this.loadPermissions()
          this.loadEstado()
          this.loadCidade(data?.Pessoa?.endereco?.cidade?.CodEstado)
          this.loading = false
        })
      } catch (error) {
        this.Swal.fire({
          title: 'Atenção',
          text: 'Ocorreu um erro ao carregar o consultor.',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    },

    create() {
      const data = this.getForm()

      try {
        this.api.save
          .consultor(data)
          .then(res => {
            this.Swal.fire({
              title: 'Sucesso',
              text: res,
              icon: 'success',
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              this.$router.push({
                path: `/${this.$user.get().role}/consultores`,
              })
            })
          })
          .catch(() => {
            this.salvando = !this.salvando
          })
      } catch (error) {
        this.salvando = false
        this.Swal.fire({
          title: 'Atenção',
          text: 'Ocorreu um erro ao salvar o consultor, tente novamente!',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    },

    loadPermissions() {
      this.api.get
        .listReports({ projectId: ENV_APP.ProjectID, id: this.$user.get().id })
        .then(data => {
          this.permissions = data
          this.loadingData.loadingPermissions = false
          this.loadingData.permissions = false
        })
    },

    loadNewForm() {
      this.loadEstado()
      this.loadPermissions()
      this.loading = false
    },

    loadEstado() {
      this.loadingData.estado = false
      this.api.get.estados().then(data => {
        this.estado = data
        this.loadingData.estado = true
      })
    },

    loadCidade(CodEstado) {
      this.loadingData.cidade = false
      this.api.get.cidades(CodEstado).then(data => {
        this.cidade = data
        this.loadingData.cidade = true
      })
    },

    changeCidade() {
      this.loadCidade(this.form.estado)
      this.form.cidade = ''
    },

    deleteCall() {
      this.dialogRemoveLogo = true
      this.buttonRemoveLogo = false
    },
  },
}
</script>

<style>
.v-tab--active .v-icon {
  color: #003677 !important;
}
</style>
